import * as _datatables2 from "datatables.net-bs5";

var _datatables = "default" in _datatables2 ? _datatables2.default : _datatables2;

import * as _datatables4 from "datatables.net-responsive";

var _datatables3 = "default" in _datatables4 ? _datatables4.default : _datatables4;

var exports = {};

/*! Bootstrap 5 integration for DataTables' Responsive
 * ©2021 SpryMedia Ltd - datatables.net/license
 */
(function (factory) {
  if (true) {
    // CommonJS
    exports = function (root, $) {
      if (!root) {
        root = window;
      }

      if (!$ || !$.fn.dataTable) {
        $ = _datatables(root, $).$;
      }

      if (!$.fn.dataTable.Responsive) {
        _datatables3(root, $);
      }

      return factory($, root, root.document);
    };
  } else {}
})(function ($, window, document, undefined) {
  'use strict';

  var DataTable = $.fn.dataTable;
  var _display = DataTable.Responsive.display;
  var _original = _display.modal;

  var _modal = $("<div class=\"modal fade dtr-bs-modal\" role=\"dialog\">" + "<div class=\"modal-dialog\" role=\"document\">" + "<div class=\"modal-content\">" + "<div class=\"modal-header\">" + "<button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"modal\" aria-label=\"Close\"></button>" + "</div>" + "<div class=\"modal-body\"/>" + "</div>" + "</div>" + "</div>");

  var modal; // Note this could be undefined at the time of initialisation - the
  // DataTable.Responsive.bootstrap function can be used to set a different
  // bootstrap object

  var _bs = window.bootstrap;

  DataTable.Responsive.bootstrap = function (bs) {
    _bs = bs;
  };

  _display.modal = function (options) {
    if (!modal) {
      modal = new _bs.Modal(_modal[0]);
    }

    return function (row, update, render) {
      if (!$.fn.modal) {
        _original(row, update, render);
      } else {
        if (!update) {
          if (options && options.header) {
            var header = _modal.find("div.modal-header");

            var button = header.find("button").detach();
            header.empty().append("<h4 class=\"modal-title\">" + options.header(row) + "</h4>").append(button);
          }

          _modal.find("div.modal-body").empty().append(render());

          _modal.appendTo("body").modal();

          modal.show();
        }
      }
    };
  };

  return DataTable.Responsive;
});

export default exports;